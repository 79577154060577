import axios, { AxiosError, AxiosResponse } from 'redaxios'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type Notification = { id: string; title: string; content: string }
export type Tenant = { id: string; name: string }
export type LoginAuthResponse = {
  name: string
  email: string
  tenants: Tenant[]
}
export type AuthState = {
  name: string | null
  email: string | null
  tenants: Tenant[]
  // TODO: rename current_tenant to currentTenant
  current_tenant?: Tenant | null
  notifications: Notification[]
  isAuthenticated: boolean
  http_get: (url: string) => Promise<AxiosResponse>
  http_post: (url: string, data: object) => Promise<AxiosResponse>
  http_patch: (url: string, data: object) => Promise<AxiosResponse>
  http_delete: (url: string) => Promise<AxiosResponse>
  // fetcher: (url: string) => Promise<AxiosResponse | AxiosError>
  fetcher: (url: string) => Promise<any>
  login: (auth: LoginAuthResponse) => void
  logout: () => void
  changeTenant: (tenant: Tenant) => void
  multipleTenants: () => boolean
}

export const isAuthenticationFailure = (error) => {
  return error && error.status === 400 && error.data?.error === 'invalid_token'
}

export const useAuthStore = create<AuthState>(
  // @ts-ignore
  persist(
    (set, get) => ({
      name: null,
      email: null,
      tenants: [],
      notifications: [],
      current_tenant: null,
      isAuthenticated: false,
      http_get: (url) =>
        axios.get(url, {
          headers: { 'x-tenant-id': get().current_tenant?.id },
        }),
      http_post: (url, data) => {
        console.log(`axios.post(${url}): `, { data })
        return axios.post(url, data, {
          headers: { 'x-tenant-id': get().current_tenant?.id },
        })
      },
      http_patch: (url, data) =>
        axios.patch(url, data, {
          headers: { 'x-tenant-id': get().current_tenant?.id },
        }),
      http_delete: (url) =>
        axios.delete(url, {
          headers: { 'x-tenant-id': get().current_tenant?.id },
        }),
      fetcher: (url) =>
        axios
          .get(url, { headers: { 'x-tenant-id': get().current_tenant?.id } })
          .then((res: AxiosResponse) => res.data),
      login: (auth) =>
        set({
          name: auth.name,
          email: auth.email,
          tenants: auth.tenants,
          current_tenant: auth.tenants[0], // FIXME: if user is not attached to tenant ?
          isAuthenticated: true,
        }),
      logout: () =>
        set({
          name: null,
          email: null,
          tenants: [],
          current_tenant: null,
          isAuthenticated: false,
        }),
      changeTenant: (tenant: Tenant) => set({ current_tenant: tenant }),
      multipleTenants: () => get().tenants?.length > 1,
    }),
    {
      name: 'auth-store', // name of the item in the storage (must be unique)
      // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      // skipHydration: true,
    },
  ),
)
